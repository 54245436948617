import {useOutletContext} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Divider, ListItem, Skeleton} from "@mui/material";
import GenericModal from "../../genericui/modals/GenericModal";
import Typography from "@mui/material/Typography";
import AIService from "../../../services/AIService";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import Box from "@mui/material/Box";
import EjercicioDetails from "./EjercicioDetails";
import EjercicioListItem from "./EjercicioListItem";

export default function EjerciciosList() {
    const context = useOutletContext();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [ejercicios, setEjercicios] = useState([]);
    const [isViewEjercicioOpen, setIsViewEjercicioOpen] = useState(false);
    const [selectedEjercicio, setSelectedEjercicio] = useState({});

    useEffect(() => {
        if (isFirstLoad) {
            AIService.getEjercicios().then(
                response => setEjercicios(response.data),
                _ => context.handleAPIError("No se han podido cargar tus datos. Por favor, inténtalo más tarde.")
            ).finally(() => setIsFirstLoad(false))
        }
    }, [context, isFirstLoad]);

    function mapEjerciciosIntoList() {
        return (<>
            {ejercicios.map((ejercicio, i) => {
                return (
                    <React.Fragment key={'maincontainerejercicio' + i}>
                        <Divider key={"upper-divider" + i} sx={{my: 1}}/>
                        <ListItem key={"ejerciciolistitem" + ejercicio.id}>
                            <EjercicioListItem ejercicio={ejercicio} onClick={() => {
                                handleClickOnView(ejercicio);
                            }}/>
                        </ListItem>
                        {i + 1 === ejercicios.length && <Divider key="bottom-divider" sx={{my: 1}}/>}
                    </React.Fragment>
                );
            })}
        </>);
    }

    function handleClickOnView(ejercicio) {
        setSelectedEjercicio(ejercicio);
        setIsViewEjercicioOpen(true);
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"600px"}/>);
    }

    return <>
        {isViewEjercicioOpen && <GenericModal title={selectedEjercicio.title} onClose={() => setIsViewEjercicioOpen(false)}>
            <EjercicioDetails ejercicio={selectedEjercicio.markdown}/>
        </GenericModal>}
        <Box sx={{p: 2}}>
            <Button fullWidth variant="outlined" color="primary" href="/ia/ejercicios" startIcon={<ArticleIcon/>}>Generar
                Ejercicios</Button>
        </Box>
        {ejercicios.length > 0 ? mapEjerciciosIntoList() : <Typography>📄 No tienes ningún Ejercicio guardado aún ¡Prueba generando uno y guardándolo en Mis
            Ejercicios!</Typography>}
    </>
}