import React, {useEffect, useState} from "react";
import {useOutletContext} from 'react-router-dom';
import {
    Divider,
    Grid,
    List,
    ListItem, Skeleton,
} from "@mui/material";
import Button from "@mui/material/Button";
import SuccessMessage from "../../genericui/feedback/SuccessMessage";
import StudentListItem from "./StudentListItem";
import {AddTwoTone as Add, CasinoTwoTone as Casino, PeopleTwoTone as People} from "@mui/icons-material";
import GenericModal from "../../genericui/modals/GenericModal";
import StudentsService from "../../../services/StudentsService";
import EditStudentForm from "./EditStudentForm";
import CRUDMenu from "../../genericui/menus/CRUDMenu";
import ViewStudentGrid from "./ViewStudentGrid";
import MassAddStudentForm from "./MassAddStudentsForm";
import MassAddToClassroomForm from "./MassAddToClassroomForm";
import StudentsRoulette from "./StudentsRoulette";
import {useParams} from "react-router-dom";


export default function StudentsLayout() {
    const {classroomId} = useParams();
    const context = useOutletContext();
    const [message, setMessage] = useState("");
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [isMassStudentsModalOpen, setIsMassStudentsModalOpen] = useState(false);
    const [isUpdateStudentModalOpen, setIsUpdateStudentModalOpen] = useState(false);
    const [isViewStudentModalOpen, setIsViewStudentModalOpen] = useState(false);
    const [studentsData, setStudentsData] = useState({});
    const [selectedStudent, setSelectedStudent] = useState({});
    const [isMassAddToClassroomsModalOpen, setIsMassAddToClassroomsModalOpen] = useState(false);
    const [isRandomStudentRoulette, setIsRandomStudentRoulette] = useState(false);

    useEffect(() => {
            if (isFirstLoad) {
                StudentsService.getStudentsByClassroom(classroomId).then((response) => {
                        setIsFirstLoad(false)
                        setStudentsData(response.data)
                    },
                    _ => {
                        context.handleAPIError("No se han podido cargar tus datos. Intenta de nuevo más tarde.");
                        setIsFirstLoad(false);
                    }
                )
            }
        }
    )

    function handleMultipleNewStudents(newStudents) {
        setIsMessageVisible(false);
        let currentStudents = [...studentsData];
        for (let i = 0; i < newStudents.length; i++) {
            currentStudents.push(newStudents[i]);
        }
        setStudentsData(currentStudents);
        StudentsService.getStudentsByClassroom(classroomId).then((response) => {
                setStudentsData(response.data);
            },
            _ => {
                context.handleAPIError("No se han podido cargar tus datos. Intenta de nuevo más tarde.");
            }
        )
        triggerSuccessMessage("Los alumnos han sido añadidos con éxito");
    }

    function handleEditStudent(student) {
        let currentStudents = [...studentsData];
        const index = currentStudents.map(function (x) {
            return x.id;
        }).indexOf(student.id);
        currentStudents[index] = student;
        setStudentsData(currentStudents);
        triggerSuccessMessage("El Alumno ha sido actualizado con éxito");
    }

    function handleClickOnDelete(studentId) {
        setIsMessageVisible(false);
        StudentsService.deleteStudent(studentId).then((response) => {
                let currentStudents = [...studentsData];
                currentStudents = currentStudents.filter(item => item.id !== studentId);
                setStudentsData(currentStudents);
                triggerSuccessMessage("El Alumno ha sido eliminado con éxito");
            },
            _ => context.handleAPIError("No se ha podido borrar el alumno. Recarga la página o intenta de nuevo más tarde.")
        );
    }

    function mapStudentsIntoList() {
        return (<>
            {studentsData.map((student, i) => {
                return (
                    <React.Fragment key={'maincontainerstudent' + i}>
                        <ListItem secondaryAction={
                            <CRUDMenu
                                key={i}
                                cloneTitle={"Añadir/Quitar de clases"}
                                handleClickOnEdit={() => {
                                    handleClickOnUpdate(student);
                                }}
                                handleClickOnClone={() => {
                                    handleClickOnClone(student);
                                }}
                                handleClickOnDelete={() => {
                                    handleClickOnDelete(student.id)
                                }}
                                disablePadding
                            />
                        }>
                            <StudentListItem onClick={() => {
                                handleClickOnView(student);
                            }}
                                             key={student.id + student.first_name} {...student} number={i + 1}/>
                        </ListItem>
                        {i + 1 !== studentsData.length && <Divider key={i} sx={{my: 1}}/>}
                    </React.Fragment>
                );
            })}
        </>);
    }

    function toggleViewStudentModal() {
        setIsViewStudentModalOpen(!isViewStudentModalOpen);
    }

    function toggleMassStudentsModal() {
        setIsMassStudentsModalOpen(!isMassStudentsModalOpen);
    }

    function toggleUpdateStudentModal() {
        setIsUpdateStudentModalOpen(!isUpdateStudentModalOpen);
    }

    function handleClickOnView(student) {
        toggleViewStudentModal();
        setSelectedStudent(student);
    }

    function handleClickOnUpdate(student) {
        toggleUpdateStudentModal();
        setSelectedStudent(student);
    }

    function handleClickOnClone(student) {
        toggleMassAddToClassroomsModal();
        setSelectedStudent(student);
    }

    function renderViewStudentModal() {
        const title = selectedStudent.first_name + ' ' + selectedStudent.last_name;
        return (
            <>
                <GenericModal title={title} onClose={toggleViewStudentModal} styles={{m: 1, p: 1, maxHeight: '85vh', maxWidth: '90vw'}}>
                    <ViewStudentGrid student={selectedStudent}/>
                </GenericModal>
            </>
        );
    }

    function renderEditStudentsModal() {
        const title = "Editar Alumno";
        return (
            <>
                <GenericModal title={title} onClose={toggleUpdateStudentModal}>
                    <EditStudentForm student={selectedStudent} onUpdateStudent={handleEditStudent}
                                     onClose={toggleUpdateStudentModal}
                                     id={selectedStudent.id} {...context}/>
                </GenericModal>
            </>
        );
    }

    function displayEmptyStudentsMessage() {
        return (
            <>
                <ListItem>👩‍🎓 No tienes ningún estudiante en esta Clase todavía. ¡Prueba añadiendo uno! 👨‍🎓</ListItem>
            </>
        );
    }

    function disableSuccessMessage() {
        setIsMessageVisible(!isMessageVisible);
    }

    function triggerSuccessMessage(message) {
        setIsMessageVisible(true);
        setMessage(message);
    }

    function toggleAddMassStudentModal() {
        setIsMassStudentsModalOpen(!isMassStudentsModalOpen);
    }

    function renderAddMultipleStudentsModal() {
        const title = "Añadir Alumnos";
        return (
            <>
                <GenericModal title={title} onClose={toggleAddMassStudentModal}>
                    <MassAddStudentForm onNewStudents={handleMultipleNewStudents} classroom={context.classroom}
                                        onClose={toggleAddMassStudentModal}/>
                </GenericModal>
            </>
        );
    }

    function toggleMassAddToClassroomsModal() {
        setIsMassAddToClassroomsModalOpen(!isMassAddToClassroomsModalOpen);
    }

    function renderMassAddToClassroomsModal() {
        const title = "Añadir a varias Clases";
        return (
            <>
                <GenericModal title={title} onClose={toggleMassAddToClassroomsModal}>
                    <MassAddToClassroomForm onNewStudents={handleMassAddToClassrooms}
                                            student={selectedStudent}
                                            onClose={toggleMassAddToClassroomsModal}
                                            {...context}/>
                </GenericModal>
            </>
        );
    }

    function handleMassAddToClassrooms(finalClassroomsList) {
        if (!finalClassroomsList.includes(classroomId)) {
            let currentStudents = [...studentsData];
            currentStudents = currentStudents.filter(item => item.id !== selectedStudent.id);
            setStudentsData(currentStudents);
        }
        triggerSuccessMessage("Los cambios han sido efectuados con éxito");
    }


    function renderRandomStudentRoulette() {
        const title = "Alumno aleatorio"
        return (
            <>
                <GenericModal title={title} onClose={toggleStudentsRoulette}>
                    <StudentsRoulette students={studentsData}/>
                </GenericModal>
            </>
        );
    }

    function toggleStudentsRoulette() {
        setIsRandomStudentRoulette(!isRandomStudentRoulette);
    }

    if (isFirstLoad) {
        return <Skeleton variant="rectangular" height={"80vh"}/>
    }

    return (
        <>
            {isMessageVisible && <SuccessMessage disableSuccessMessage={disableSuccessMessage} message={message}/>}
            <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
                <Grid item sx={{m: 2}} xs={12} lg={4}>
                    <Button onClick={toggleMassStudentsModal} onClose={toggleMassStudentsModal} size="small"
                            variant="contained" startIcon={<Add/>} fullWidth> <People/> {'\u00A0'} Añadir Alumnos
                    </Button>
                </Grid>
                {studentsData.length > 0 && <Grid item sx={{m: 2}} xs={12} lg={4}>
                    <Button onClick={toggleStudentsRoulette}
                            onClose={toggleStudentsRoulette}
                            variant="contained"
                            fullWidth
                            size="small"
                            startIcon={<Casino/>}>Elegir alumno al azar</Button>
                </Grid>}
            </Grid>
            <Divider/>
            <Grid container sx={{p: 0}}>
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {studentsData.length > 0 ? mapStudentsIntoList() : displayEmptyStudentsMessage()}
                    {isViewStudentModalOpen && renderViewStudentModal()}
                    {isMassStudentsModalOpen && renderAddMultipleStudentsModal()}
                    {isUpdateStudentModalOpen && renderEditStudentsModal()}
                    {isMassAddToClassroomsModalOpen && renderMassAddToClassroomsModal()}
                    {isRandomStudentRoulette && renderRandomStudentRoulette()}
                </List>
            </Grid>
            {studentsData.length > 4 && <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
                <Grid item xs={12}><Divider/></Grid>
                <Grid item sx={{m: 2}} xs={12} lg={4}>
                    <Button onClick={toggleMassStudentsModal} onClose={toggleMassStudentsModal} size="small"
                            variant="contained" startIcon={<Add/>} fullWidth> <People/> {'\u00A0'} Añadir Alumnos
                    </Button>
                </Grid>
            </Grid>}
        </>
    );
}