import "./styles.css"
import React, {useEffect, useState} from "react";
import {Divider, Tab, Tabs} from "@mui/material";
import {FamilyRestroomTwoTone, ScheduleTwoTone, TodayTwoTone, EventNoteTwoTone} from "@mui/icons-material";
import MainCard from "./genericui/cards/MainCard";
import ErrorMessage from "./genericui/feedback/ErrorMessage";
import {Link, Outlet} from "react-router-dom";
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';


export default function Agenda() {
    const [currentTab, setCurrentTab] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        function setTabFromPath() {
            const path = document.location.pathname.toString();
            if (path.includes("planner")) {
                setCurrentTab(1);
            } else if (path.includes("diario")) {
                setCurrentTab(2);
            } else if (path.includes("reuniones")) {
                setCurrentTab(3);
            } else if (path.includes("tutorias")) {
                setCurrentTab(4);
            } else {
                setCurrentTab(0);
            }
        }

        setTabFromPath()
        window.onpopstate = () => {
            setTabFromPath()
        }

    }, [])

    function handleAPIError(message) {
        setErrorMessage(message);
        setIsErrorMessageVisible(true);
    }

    return <MainCard>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={errorMessage}/>}
        <Divider/>
        <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)} variant="scrollable"
              allowScrollButtonsMobile>
            <Tab icon={<FactCheckTwoToneIcon/>} wrapped label="Tareas" sx={{width: '20%'}} component={Link} to="/agenda/"/>
            <Tab icon={<ScheduleTwoTone/>} wrapped label="Planner" sx={{width: '20%'}} component={Link} to="/agenda/planner"/>
            <Tab icon={<TodayTwoTone/>} wrapped label="Diario" sx={{width: '20%'}} component={Link} to="/agenda/diario/"/>
            <Tab icon={<EventNoteTwoTone/>} wrapped label="Reuniones" sx={{width: '20%'}} component={Link}
                 to="/agenda/reuniones/"/>
            <Tab icon={<FamilyRestroomTwoTone/>} wrapped label="Tutorías" sx={{width: '20%'}} component={Link}
                 to="/agenda/tutorias/"/>
        </Tabs>
        <Outlet context={{handleAPIError}}/>
    </MainCard>
}