import ListItemButton from "@mui/material/ListItemButton";
import {ListItemAvatar, Typography} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {AutoFixHighTwoTone as AutoFixHighIcon} from "@mui/icons-material";

export default function SDAListItem(props) {
    const {sda, onClick} = props;
    const date = new Date(sda.created_at);

    return (
        <ListItemButton onClick={onClick}>
            <ListItemAvatar>
                <AutoFixHighIcon/>
            </ListItemAvatar>
            <ListItemText primary={sda.title} secondary={
                <>
                    {sda.level + " - "}
                    <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body"
                    >
                        {sda.subject}
                    </Typography>
                    {" - "}{date.toLocaleString()}
                </>}/>
        </ListItemButton>
    );
}
