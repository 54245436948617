import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {HomeTwoTone as HomeIcon} from "@mui/icons-material";
import {Divider, Tab, Tabs} from "@mui/material";
import {ArrowBackTwoTone as ArrowBack, AssignmentTurnedInTwoTone as AssignmentTurnedIn, BookTwoTone as Book, CheckTwoTone as Check, PeopleTwoTone as People} from "@mui/icons-material";
import MainCard from "../../genericui/cards/MainCard";
import {SchoolTwoTone as SchoolIcon} from "@mui/icons-material";
import ClassroomService from "../../../services/ClassroomService";
import {Link, Outlet, useOutletContext} from "react-router-dom";
import {useEffect, useState} from "react";
import ErrorMessage from "../../genericui/feedback/ErrorMessage";
import SuccessMessage from "../../genericui/feedback/SuccessMessage";
import SubjectService from "../../../services/SubjectService";


export default function ClassroomPanelLayout(props) {
    const context = useOutletContext();
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [classroom, setClassroom] = useState({});
    const [subjectName, setSubjectName] = useState("");
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        const promises = [ClassroomService.getClassroomById(context.classroomId)];

        const path = document.location.pathname.toString();
        if (path.includes("asignaturas")) {
            setCurrentTab(1);
            promises.push(SubjectService.getSubjectsByClassroom(context.classroomId))
        } else if (path.includes("asistencia")) {
            setCurrentTab(2);
            setSubjectName("");
        } else if (path.includes("entregas")) {
            setCurrentTab(3);
            setSubjectName("");
        } else {
            setCurrentTab(0);
            setSubjectName("");
        }
        Promise.all(promises).then(([classroomResponse, subjectResponse]) => {
                setClassroom(classroomResponse.data);
                if (subjectResponse) {
                    const subjects = subjectResponse.data;
                    const currentSubjectName = subjects.find(subject => subject.id === parseInt(context.subjectId))?.name;
                    setSubjectName(currentSubjectName);
                }
                setIsFirstLoad(false);
            },
            _ => handleAPIError("No se han podido cargar los datos de la clase. Intenta de nuevo más tarde.")
        );
    }, [context])

    function handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    function showSuccessMessage(message) {
        setMessage(message);
        setIsSuccessMessageVisible(true);
    }

    function handleClick(event) {
        event.preventDefault();
    }

    function icon() {
        return (
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        sx={{display: 'flex', alignItems: 'center'}}
                        color="primary"
                        to="/clases/"
                    >
                        <ArrowBack color="primary" sx={{mr: 0.5}} fontSize="inherit"/>
                        <HomeIcon color="primary" sx={{mr: 0.5}} fontSize="inherit"/>
                        4Docent.es
                    </Link>
                    <Link
                        underline="hover"
                        sx={{display: 'flex', alignItems: 'center'}}
                        color="primary"
                        to={"/clases/" + classroom.id + "/"}
                    >
                        <SchoolIcon color="primary" sx={{mr: 0.5}} fontSize="inherit"/>
                        {"Clase " + classroom.name}
                    </Link>
                    {subjectName && <Typography
                        underline="hover"
                        sx={{display: 'flex', alignItems: 'center'}}
                        color="primary"
                    >
                        <AssignmentTurnedIn color="primary" sx={{mr: 0.5}} fontSize="inherit"/>
                        {subjectName}
                    </Typography>}
                </Breadcrumbs>
            </div>
        );
    }

    function handleTabChange(event, newValue) {
        setCurrentTab(newValue);
    }

    if (isFirstLoad) {
        return <></>
    }

    return (
        <>
            <MainCard header={icon()}>
                {isErrorMessageVisible &&
                    <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
                {isSuccessMessageVisible &&
                    <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
                <Divider/>
                <Tabs value={currentTab} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                    <Tab icon={<People/>} wrapped label="Alumnos" sx={{width: '25%'}} component={Link}
                         to={"/clases/" + classroom.id}/>
                    <Tab icon={<AssignmentTurnedIn/>} wrapped label="Asignaturas" sx={{width: '25%'}} component={Link}
                         to={"/clases/" + classroom.id + "/asignaturas"}/>
                    <Tab icon={<Check/>} wrapped label="Asistencia" sx={{width: '25%'}} component={Link}
                         to={"/clases/" + classroom.id + "/asistencia"}/>
                    <Tab icon={<Book/>} wrapped label="Entregas" sx={{width: '25%'}} component={Link}
                         to={"/clases/" + classroom.id + "/entregas"}/>
                </Tabs>
                <Outlet context={{handleAPIError, showSuccessMessage}}/>
            </MainCard>
        </>
    );
}
