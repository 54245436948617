import * as React from 'react';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {EditTwoTone as Edit} from "@mui/icons-material";

export default function EditDataGridColumnMenuItem(props) {
    return (
            <MenuItem onClick={props.handleClick}>
                <ListItemIcon>
                    <Edit fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Editar columna</ListItemText>
            </MenuItem>
    );
}
