import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useState} from "react";
import EvaluationService from "../../../services/EvaluationService";
import Button from "@mui/material/Button";
import {CheckBoxTwoTone as CheckBox, CheckBoxOutlineBlankTwoTone as CheckBoxOutlineBlank, DownloadTwoTone as Download} from "@mui/icons-material";
import {Autocomplete, Checkbox, Chip, Divider, Grid, Skeleton, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {exportExcel} from "../evaluation/presenters/ExcelPresenter";
import StudentsService from "../../../services/StudentsService";

const AVERAGE_ID = -999999999;
const NAME_AND_NUMBER_SUFFIX_COLUMNS = [
    {
        field: 'Nombre2',
        headerName: 'Nombre',
        minWidth: 150,
        type: 'string',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'left',
        align: "left",
    },
    {
        field: 'Numero2',
        headerName: 'Número',
        width: 80,
        type: 'number',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'center',
        align: "right",
    },
]
const NAME_AND_NUMBER_PREFIX_COLUMNS = [
    {
        field: 'Numero',
        headerName: 'Número',
        width: 80,
        type: 'number',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'center',
        align: "right",
    },
    {
        field: 'Nombre',
        headerName: 'Nombre',
        minWidth: 150,
        type: 'string',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'left',
        align: "left",
    },
];
const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

export default function SubjectSummaryTable(props) {
    const {classroomId} = useParams();
    const {subjectId, studentId} = props;
    const [currentSubjectId, setCurrentSubjectId] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(NAME_AND_NUMBER_PREFIX_COLUMNS);
    const [tabs, setTabs] = useState([]);
    const [selectedTabs, setSelectedTabs] = useState([]);
    const [students, setStudents] = useState([]);

    function getActivityType(activity) {
        if (activity.type === "Sobre 10") {
            return "number";
        }
        return "singleSelect";
    }

    function dateValue(dateString) {
        const date = new Date(dateString);
        return date.getDate() + "/" + (date.getMonth() + 1);
    }

    function customRenderHeader(params) {
        return <div style={{width: "auto"}}>
            <Box sx={{textAlign: "center", fontWeight: 'bold'}}><Typography
                sx={{wordWrap: "break-word", whiteSpace: "normal"}}>{params.colDef.headerName}</Typography></Box>
            <Box sx={{textAlign: "center"}}><Typography variant="subtitle2">{params.colDef.dates}</Typography></Box>
            <Box sx={{textAlign: "center"}}><Typography
                variant="subtitle2">{params.colDef.percentage !== null ? params.colDef.percentage + "%" : ""}</Typography></Box>
        </div>
    }

    const presentActivitiesByTab = useCallback((data) => {
        const localColumns = [...NAME_AND_NUMBER_PREFIX_COLUMNS];
        data.forEach((element) => {
            const description = element.criterios.length > 0 ? "Criterios " + element.criterios.map(item => item.code).join(", ") : "Sin criterios";
            localColumns.push({
                id: element.id,
                field: element.id.toString(),
                headerName: element.title,
                description: description,
                minWidth: element.width,
                type: getActivityType(element),
                headerAlign: 'center',
                editable: element.id !== AVERAGE_ID,
                headerClassName: element.id === AVERAGE_ID ? 'evaluations--final' : 'evaluations--header',
                align: 'center',
                dates: element.start_date ? dateValue(element.start_date) + ' - ' + dateValue(element.end_date) : "",
                marks: element.marks,
                percentage: element.percentage,
                renderHeader: customRenderHeader,
                criterios: element.criterios,
                start_date: element.start_date,
                end_date: element.end_date,
                mark_type: element.type,
            });
        })
        return localColumns;
    }, [])


    useEffect(() => {
        if (tabs.length === 0 || props.subjectId !== currentSubjectId) {
            EvaluationService.getTabs(classroomId, subjectId)
                .then(tabsResponse => {
                        setCurrentSubjectId(props.subjectId)
                        setTabs(tabsResponse.data);
                        setSelectedTabs(tabsResponse.data);
                    }
                )
        } else {
            const selectedTabsIds = selectedTabs.map(element => element.id);
            Promise.all([
                EvaluationService.getFinalMarksSummaryByTabs(classroomId, subjectId, selectedTabsIds),
                StudentsService.getStudentsByClassroom(classroomId),
            ]).then(([marksResponse, studentsResponse]) => {
                    const newColumns = presentActivitiesByTab(marksResponse.data);
                    const filledRows = setAllCellsToMinimumValue(newColumns, studentsResponse.data);
                    setStudents(studentsResponse);
                    setRows(filledRows);
                    setColumns(newColumns);
                    setIsFirstLoad(false);
                },
                _ => props.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde.")
            )
        }
    }, [classroomId, props, subjectId, presentActivitiesByTab, selectedTabs, tabs])

    function setAllCellsToMinimumValue(receivedColumns, rowsToProcess) {
        let processedRows = studentId ? rowsToProcess.filter(x => x.id === studentId) : [...rowsToProcess];

        processedRows.forEach((currentRow, i) => {
            receivedColumns.forEach(column => {
                if (column.field !== "Nombre" && currentRow["Nombre"] !== "Nombre" && column.field !== "Numero" && currentRow["Numero"] !== "Número" && column.field !== "Nombre2" && currentRow["Nombre2"] !== "Nombre" && column.field !== "Numero2" && currentRow["Numero2"] !== "Número") {
                    currentRow[column.field] = 0;
                }
            });
            currentRow.Numero = i + 1;
            currentRow.Nombre = currentRow.last_name + ", " + currentRow.first_name;
            currentRow.Nombre2 = currentRow.last_name + ", " + currentRow.first_name;
            currentRow.Numero2 = i + 1;
        });

        let currentRowIndex;
        receivedColumns.forEach((column) => {
            if (column.marks) {
                column.marks.forEach(mark => {
                    currentRowIndex = processedRows.map(x => x.id).indexOf(mark.student_id);
                    if (currentRowIndex > -1) {
                        processedRows[currentRowIndex][column.field] = mark.value;
                    }
                })
            }
        });
        return processedRows;
    }

    function getRowClassName(params) {
        if (params.row.id === 0) {
            return "criterios";
        }

        return '';
    }

    function handleSelectedTabsChange(event, values) {
        setSelectedTabs(values);
        const selectedTabsIds = values.map(element => element.id);
        setIsFirstLoad(true);
        EvaluationService.getFinalMarksSummaryByTabs(classroomId, subjectId, selectedTabsIds).then((response) => {
                const newColumns = presentActivitiesByTab(response.data);
                const filledRows = setAllCellsToMinimumValue(newColumns, students);
                setRows(filledRows);
                setColumns(newColumns);
                setIsFirstLoad(false);
            },
            _ => props.handleAPIError("Ha ocurrido un error cargando los datos. Recarga la página o intenta de nuevo más tarde.")
        )
    }

    if (isFirstLoad) {
        return (<Skeleton variant="rectangular" height={"800px"}/>);
    }

    return <>
        <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
            <Grid item xs={12}>
                <Autocomplete
                    id="tabs"
                    name='tabs'
                    multiple
                    fullWidth
                    value={selectedTabs}
                    onChange={handleSelectedTabsChange}
                    disableCloseOnSelect={true}
                    options={tabs.slice(0, -1)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip variant="outlined" label={option.name} {...getTagProps({index})}/>
                        ));
                    }
                    }
                    renderOption={(props, option, state) => {
                        const isChecked = selectedTabs.some(element => element.id === option.id);
                        return <>
                            <li {...props}>
                                <Grid container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                      columns={16}
                                >
                                    <Grid item xs={2}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={state.selected || isChecked}
                                        />
                                    </Grid>
                                    <Grid item xs={14}>
                                        <Typography component='div'>
                                            {option.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                            <Divider/>
                        </>
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            fullWidth
                            id="tabs-input"
                            label="Pestañas"
                            size='medium'
                            variant="standard"
                            helperText="Selecciona qué pestañas quieres que aparezcan en el resumen."
                        />
                    )}
                />
            </Grid>
        </Grid>
        <Box sx={{whiteSpace: "normal !important"}}>
            <Box>
                <Button onClick={() => exportExcel(rows, columns, "Notas")} startIcon={<Download/>}>Descargar a
                    Excel</Button>
            </Box>
            <Box sx={{height: "65vh"}}>
                <DataGrid
                    onProcessRowUpdateError={(error) => console.log(error)}
                    rowModel='server'
                    columns={columns.length > 4 ? columns.concat(NAME_AND_NUMBER_SUFFIX_COLUMNS) : columns}
                    rows={rows}
                    isCellEditable={(params) => params.row.id !== 0}
                    getCellClassName={(item) => {
                        if (item.value !== null) {
                            return 'filled';
                        }
                        return '';
                    }}
                    getRowClassName={(item) => {
                        if (item.indexRelativeToCurrentPage % 2 === 0 && item.id > 0) {
                            return getRowClassName(item) + " odd";
                        }
                        return getRowClassName(item);
                    }}
                    disableColumnMenu
                    rowHeight={35}
                    columnHeaderHeight={95}
                    hideFooter
                />
            </Box>
        </Box></>
}
