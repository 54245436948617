import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useCountdown} from "./genericui/info/countdown";
import SettingsService from "../services/SettingsService";
import {Card, CardActionArea, CardContent} from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";

const SubscriptionMenuItem = (props) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState(theme.palette.warning);
    const [endDate, setEndDate] = useState(null);
    const [days, hours, minutes, seconds] = useCountdown(endDate);
    const [headerText, setHeaderText] = useState("Cargando...");

    useEffect(() => {
        SettingsService.getProfileInfo().then(response => {
            const date = new Date(response.data.end_date);
            setEndDate(date);
            if (response.data.is_trial) {
                setHeaderText("Prueba Gratuita activa")
                // setBackgroundColor(theme.palette.warning.main)
                setIsLoading(false);
            }
            /*if (response.data.is_subscription_active) {
                setHeaderText("4Docentes Premium")
                setBackgroundColor(theme.palette.primary.main)
            }*/

        })
    }, []);

    if (isLoading) return null;

    return <Card>
        <CardActionArea href="/ajustes/" sx={{backgroundColor: backgroundColor, color: "white"}}>
            <CardContent>
                <Typography fontWeight="bold" variant="h6">{headerText}</Typography>
                {days < 1 && days >= 0 ? <><Typography>Termina en {days}d {hours}h {minutes}m {seconds}s</Typography><Typography>¡Haz click para suscribirte!</Typography></> :
                    <Typography>Termina el {endDate.toLocaleDateString()}</Typography>}
            </CardContent>
        </CardActionArea>
    </Card>
}

export default SubscriptionMenuItem;