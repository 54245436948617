import MenuItem from "@mui/material/MenuItem";
import {CircleTwoTone as CircleIcon} from "@mui/icons-material";
import React from "react";
import {FormControl, InputLabel, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export default function ColorsSelectInput(props) {
    const color = props.color;
    const handleChange = props.handleChange;
    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <InputLabel required id="color">Color</InputLabel>
            <Typography>: {'\u00A0'}</Typography>
            <FormControl required>
                <Select
                    id="color-select"
                    size='small'
                    value={color}
                    required
                    onChange={(e) => handleChange(e.target.value, "color")}
                >
                    <MenuItem value={"#D50000"}>
                        <CircleIcon fontSize="small" sx={{color: "#D50000"}}/>
                    </MenuItem>
                    <MenuItem value={"#E67C73"}>
                        <CircleIcon fontSize="small" sx={{color: "#E67C73"}}/>
                    </MenuItem>
                    <MenuItem value={"#F4511E"}>
                        <CircleIcon fontSize="small" sx={{color: "#F4511E"}}/>
                    </MenuItem>
                    <MenuItem value={"#F6BF26"}>
                        <CircleIcon fontSize="small" sx={{color: "#F6BF26"}}/>
                    </MenuItem>
                    <MenuItem value={"#33B679"}>
                        <CircleIcon fontSize="small" sx={{color: "#33B679"}}/>
                    </MenuItem>
                    <MenuItem value={"#0B8043"}>
                        <CircleIcon fontSize="small" sx={{color: "#0B8043"}}/>
                    </MenuItem>
                    <MenuItem value={"#039BE5"}>
                        <CircleIcon fontSize="small" sx={{color: "#039BE5"}}/>
                    </MenuItem>
                    <MenuItem value={"#3F51B5"}>
                        <CircleIcon fontSize="small" sx={{color: "#3F51B5"}}/>
                    </MenuItem>
                    <MenuItem value={"#7986CB"}>
                        <CircleIcon fontSize="small" sx={{color: "#7986CB"}}/>
                    </MenuItem>
                    <MenuItem value={"#8E24AA"}>
                        <CircleIcon fontSize="small" sx={{color: "#8E24AA"}}/>
                    </MenuItem>
                    <MenuItem value={"#616161"}>
                        <CircleIcon fontSize="small" sx={{color: "#616161"}}/>
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
</>);
}