import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import StudentsService from "../../../services/StudentsService";
import EvaluationService from "../../../services/EvaluationService";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {Skeleton} from "@mui/material";
import Button from "@mui/material/Button";
import {exportExcel} from "../evaluation/presenters/ExcelPresenter";
import {DownloadTwoTone as Download} from "@mui/icons-material";

const NAME_AND_NUMBER_PREFIX_COLUMNS = [
    {
        field: 'Numero',
        headerName: 'Número',
        width: 80,
        type: 'number',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'center',
        align: "right",
    },
    {
        field: 'Nombre',
        headerName: 'Nombre',
        minWidth: 150,
        type: 'string',
        editable: false,
        headerClassName: 'evaluations--header',
        marks: [],
        headerAlign: 'left',
        align: "left",
    },
];

const AllSubjectsTable = (props) => {
    const {classroomId} = useParams();
    const {studentId} = props;
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const fillRows = (receivedRows, receivedColumns) => {
            let localRows = studentId ? receivedRows.filter(x => x.id === studentId) : [...receivedRows];

            localRows.forEach((currentRow, i) => {
                receivedColumns.forEach(column => {
                    if (column.field !== "Nombre" && currentRow["Nombre"] !== "Nombre" && column.field !== "Numero" && currentRow["Numero"] !== "Número") {
                        currentRow[column.title] = 0;
                    }
                })
                currentRow.Numero = i + 1;
                currentRow.Nombre = currentRow.last_name + ", " + currentRow.first_name;
                i++;
            })

            let currentRowIndex = 0;
            receivedColumns.forEach((column) => {
                if (column.marks) {
                    column.marks.forEach(mark => {
                        currentRowIndex = localRows.map(x => x.id).indexOf(mark.student_id);
                        if (currentRowIndex > -1) {
                            localRows[currentRowIndex][column.title] = mark.value;
                        }
                    })
                }
                column.field = column.title;
                column.headerName = column.title;
                column.minWidth = 250;
                column.headerAlign = 'center';
                column.align = 'center';
            });
            setColumns(NAME_AND_NUMBER_PREFIX_COLUMNS.concat(receivedColumns));
            setRows(localRows);
        }

        Promise.all([
            StudentsService.getStudentsByClassroom(classroomId),
            EvaluationService.getFinalMarksForAllSubjects(classroomId),
        ]).then(([studentsResponse, marksResponse]) => {
            fillRows(studentsResponse.data, marksResponse.data);
        })
    }, []);

    function getRowClassName(params) {
        if (params.row.id === 0) {
            return "criterios";
        }

        return '';
    }

    return (
        <>
            <Box>
                <Button onClick={() => exportExcel(rows, columns, "Notas")} startIcon={<Download/>}>Descargar a
                    Excel</Button>
            </Box>
            <Box sx={{height: "65vh"}}>
                {rows.length > 0 ? <DataGrid
                    columns={columns}
                    rows={rows}
                    getCellClassName={(item) => {
                        if (item.value !== null) {
                            return 'filled';
                        }
                        return '';
                    }}
                    getRowClassName={(item) => {
                        if (item.indexRelativeToCurrentPage % 2 === 0 && item.id > 0) {
                            return getRowClassName(item) + " odd";
                        }
                        return getRowClassName(item);
                    }}
                    disableColumnMenu
                    rowHeight={35}
                    columnHeaderHeight={95}
                    hideFooter
                /> : <Skeleton width="100%" height="100%" variant="rectangular"/>}
            </Box>
        </>
    );
};

export default AllSubjectsTable;