import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import * as React from "react";
import {Divider, Grid, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import {CalendarTodayTwoTone as CalendarToday, TodayTwoTone as Today} from "@mui/icons-material";
import EventsSummary from "./EventsSummary";
import SuccessMessage from "../genericui/feedback/SuccessMessage";
import TodoList from "./TodoList";


export default function HomePage(props) {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const TODAY = new Date();
    const YESTERDAY = getYesterdayDate();
    const TOMORROW = getTomorrowDate();

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        }
    }, [isFirstLoad]);

    function getYesterdayDate() {
        let yesterday = new Date(TODAY);
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    }

    function getTomorrowDate() {
        let tomorrow = new Date(TODAY);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    function handleTabChange(event, newValue) {
        setCurrentTab(newValue);
    }

    function _handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    if (isFirstLoad) {
        return <Skeleton variant="rectangular" height={"1000px"}/>
    }
    return <Box sx={{flexGrow: 1}}>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
        {isSuccessMessageVisible &&
            <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
        <Grid container sx={{p: 4, display: 'flex'}}>
            <Grid item xs={12} md={5.5}>
                <Typography align="center" component="h2" variant="h5">📋 Lista de tareas pendientes
                    (TO-DO List)</Typography>
                <Typography align="center">Organiza tus tareas diarias y mantén el control de tus
                    actividades.</Typography>
                <TodoList/>
            </Grid>
            <Grid item container xs={0} md={1} justifyContent="center" alignItems="center">
                <Divider orientation="vertical"/>
            </Grid>
            <Grid item xs={12} md={0.0001}>
                <Divider orientation="horizontal"/>
            </Grid>
            <Grid item xs={12} md={5.5}>
                <Typography align="center" component="h2" variant="h5">Tu día</Typography>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab icon={<CalendarToday/>} wrapped label="Ayer" sx={{width: '33%'}}/>
                    <Tab icon={<Today/>} wrapped label="Hoy" sx={{width: '33%'}}/>
                    <Tab icon={<CalendarToday/>} wrapped label="Mañana" sx={{width: '33%'}}/>
                </Tabs>
                <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
                    {currentTab === 0 && <EventsSummary date={YESTERDAY} handleAPIError={_handleAPIError}/>}
                    {currentTab === 1 && <EventsSummary date={TODAY} handleAPIError={_handleAPIError}/>}
                    {currentTab === 2 && <EventsSummary date={TOMORROW} handleAPIError={_handleAPIError}/>}
                </Grid>
            </Grid>
        </Grid>
    </Box>
        ;
}