import StudentsService from "../../../services/StudentsService";
import React, {useEffect, useState} from "react";
import {useOutletContext} from 'react-router-dom';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import {Grid, Skeleton} from "@mui/material";
import {PanoramaFishEyeTwoTone as PanoramaFishEye} from "@mui/icons-material";
import MarksSummary from "./MarksSummary";
import EvaluationService from "../../../services/EvaluationService";
import EvaluationTable from "./EvaluationTable";
import EvaluationTabs from "./tabs/EvaluationTabs";
import {useParams} from "react-router-dom";
import {evaluationBoxStyles} from "../utils";

export default function EvaluationLayout() {
    const context = useOutletContext();
    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const {classroomId, subjectId} = useParams();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [criterios, setCriterios] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentTabId, setCurrentTabId] = useState(0);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        const tab_id = new URLSearchParams(window.location.search).get("tab_id");
        window.onpopstate = () => {
            const newCurrentTabId = tab_id ? tab_id : tabs[0].id;
            const newTabIndex = tabs.findIndex(tab => tab.id === parseInt(newCurrentTabId));
            setCurrentTabId(newCurrentTabId);
            setCurrentTab(newTabIndex > -1 ? newTabIndex : 0);
        }
        const lomloe = new URLSearchParams(window.location.search).get("lomloe");
        setIsSummaryOpen(!!lomloe);
        if (isFirstLoad) {
            setIsFirstLoad(false);
            const FINAL_MARKS_TAB = {
                id: 0,
                subject_id: subjectId,
                name: "NOTAS FINALES",
                color: "#880388"
            }
            Promise.all([
                EvaluationService.getTabs(classroomId, subjectId),
                StudentsService.getStudentsByClassroom(classroomId),
                EvaluationService.getCriteriaBySubject(subjectId),
            ]).then(([receivedTabsResponse, studentsResponse, criteriaResponse]) => {
                    setTabs(receivedTabsResponse.data.concat(FINAL_MARKS_TAB));
                    setCurrentTabId(tab_id ? tab_id : receivedTabsResponse.data[0].id);
                    const receivedStudents = processReceivedRows(studentsResponse.data);
                    setStudents(receivedStudents);
                    setCriterios(criteriaResponse.data);
                    setIsLoading(false);
                },
                _ => context.handleAPIError("Ha ocurrido un error cargando tus datos. Por favor, inténtalo de nuevo más tarde.")
            );
        }
    }, [classroomId, isFirstLoad, context, subjectId, tabs, currentTabId])

    function processReceivedRows(received) {
        const processedRows = [];
        received.forEach((element, i) => {
            const received = {
                id: element.id,
                Nombre: element.first_name + " " + element.last_name,
                Numero: i + 1,
                Nombre2: element.first_name + " " + element.last_name,
                Numero2: i + 1,
            };
            processedRows.push(received);
        });
        return processedRows;
    }

    function handleTabChange(value) {
        window.history.pushState({}, "4Docent.es", "?tab_id=" + tabs[value].id);
        setCurrentTabId(tabs[value].id);
        setCurrentTab(value);
    }

    function handleSummaryChange() {
        const urlStoryStateSuffix = !isSummaryOpen ? "?lomloe=true" : "?tab_id=" + currentTabId;
        window.history.pushState({}, "4Docent.es", urlStoryStateSuffix);
        setIsSummaryOpen(v => !v);
    }

    return <Box sx={evaluationBoxStyles}>
        <Grid container sx={{p: 0, display: 'flex', alignItems: 'center'}}>
            <Grid item xs={12} md={4} sx={{m: 1}}>
                {isFirstLoad ? <Skeleton/> :
                    <Button variant="outlined" fullWidth size="small" onClick={handleSummaryChange}
                            startIcon={<PanoramaFishEye/>}>
                        {isSummaryOpen ? "Ocultar Resumen" : "Ver Resumen LOMLOE"}
                    </Button>}
            </Grid>
        </Grid>
        {isLoading ? <Skeleton/> : <>{
            isSummaryOpen ?
                <MarksSummary students={students} criterios={criterios} tabs={tabs} {...context}/> :
                <><EvaluationTabs tabs={tabs} setTabs={setTabs} currentTab={currentTab}
                                  setCurrentTab={handleTabChange} {...context}/>
                    {isFirstLoad ? <Skeleton/> :
                        <EvaluationTable students={students} criterios={criterios} tab={currentTabId}
                                         tabs={tabs} {...context}/>}
                </>
        }</>
        }
    </Box>
}
