import {Link, Paper} from "@mui/material";
import React, {useState} from "react";
import ErrorMessage from "../genericui/feedback/ErrorMessage";
import {Outlet} from "react-router-dom";
import SuccessMessage from "../genericui/feedback/SuccessMessage";
import Box from "@mui/material/Box";
import {ArrowBackTwoTone} from "@mui/icons-material";
import Breadcrumbs from "@mui/material/Breadcrumbs";


export default function IALayout() {
    const [message, setMessage] = useState("");
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

    function handleAPIError(message) {
        setMessage(message);
        setIsErrorMessageVisible(true);
    }

    function handleSuccessMessage(message) {
        setMessage(message);
        setIsSuccessMessageVisible(true);
    }


    return <Paper>
        {isErrorMessageVisible &&
            <ErrorMessage disableErrorMessage={() => setIsErrorMessageVisible(false)} message={message}/>}
        {isSuccessMessageVisible &&
            <SuccessMessage disableSuccessMessage={() => setIsSuccessMessageVisible(false)} message={message}/>}
        <Box sx={{p: 1}}>
            <div role="presentation" onClick={() => {
            }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        sx={{display: 'flex', alignItems: 'center',}}
                        color="inherit"
                        href="/ia/"
                        underline="hover"
                    >
                        <ArrowBackTwoTone color="inherit" sx={{mr: 0.5}} fontSize="inherit"/>
                        Volver al Catálogo de IAs
                    </Link>
                </Breadcrumbs>
            </div>
        </Box>
        <Outlet context={{handleAPIError, handleSuccessMessage}}/>
    </Paper>
}