import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {IconButton} from "@mui/material";
import {ContentCopyTwoTone as ContentCopy, DeleteTwoTone as Delete, EditTwoTone as Edit, MoreVertTwoTone as MoreVert} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Component} from "react";
import {withRouter} from "../../../withRouter";

class CRUDMenu extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            anchorEl: null,
            open: Boolean(null),
        }
    }

    handleClick(event) {
        this.setState({
            anchorEl: event.currentTarget,
            open: Boolean(event.currentTarget),
        });
    };

    handleClose(onCloseAction) {
        this.setState({
            anchorEl: null,
            open: Boolean(null),
        });
        if (typeof onCloseAction === "function") {
            onCloseAction();
        }
    };

    render() {
        return (
            <ListItemIcon>
                <IconButton
                    id="more-button"
                    aria-controls={this.state.open ? 'more-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}
                    edge='end'
                >
                    <MoreVert color="primary"/>
                </IconButton>
                <Menu
                    id="more-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'more-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {this.props.handleClickOnEdit && <MenuItem onClick={() => {
                        this.handleClose(this.props.handleClickOnEdit)
                    }}>
                        <ListItemIcon>
                            <Edit color="primary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText
                            color="primary">{this.props.editTitle !== undefined ? this.props.editTitle : "Editar"}</ListItemText>
                    </MenuItem>}

                    {this.props.handleClickOnClone && <MenuItem onClick={() => {
                        this.handleClose(this.props.handleClickOnClone)
                    }}>
                        <ListItemIcon>
                            <ContentCopy color="primary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText
                            color="primary">{this.props.cloneTitle !== undefined ? this.props.cloneTitle : "Copiar"}</ListItemText>
                    </MenuItem>}

                    {this.props.handleClickOnDelete && <MenuItem onClick={() => {
                        this.handleClose(this.props.handleClickOnDelete)
                    }}>
                        <ListItemIcon>
                            <Delete color="primary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText
                            color="primary">{this.props.deleteTitle !== undefined ? this.props.deleteTitle : "Eliminar"}</ListItemText>
                    </MenuItem>}
                </Menu>
            </ListItemIcon>
        );
    }
}

export default withRouter(CRUDMenu);