import GenericModal from "../../genericui/modals/GenericModal";
import {
    Autocomplete,
    Checkbox, Chip,
    createFilterOptions,
    DialogActions,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import TextFormInput from "../../genericui/formcomponents/TextFormInput";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {CheckBoxTwoTone as CheckBox, CheckBoxOutlineBlankTwoTone as CheckBoxOutlineBlank} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {DatePicker, esES, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import {ACTIVITY_TYPES} from "./ValueOptions";


const icon = <CheckBoxOutlineBlank fontSize="small"/>;
const checkedIcon = <CheckBox fontSize="small"/>;

export default function EditActivityModal(props) {
    const column = props.column;
    const [currentFormData, setCurrentFormData] = useState({
        id: column.id,
        title: column.headerName,
        criterios_list: column.criterios.map(element => {
            const newElement = {...element};
            newElement.label = element.code;
            delete newElement.code;
            return newElement;
        }),
        start_date: column.end_date ? new Date(column.start_date) : new Date(),
        end_date: column.end_date ? new Date(column.end_date) : new Date(),
        percentage: column.percentage,
        type: column.mark_type,
    });

    function submitChanges(event) {
        event.preventDefault();
        props.onSubmit(currentFormData);
        props.onClose();
    }

    function handleFormFieldChange(event) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData[event.target.id] = event.target.value;
        setCurrentFormData(localCurrentFormData);
    }

    function handleTypeFieldChange(event, value) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData.type = value.value;
        setCurrentFormData(localCurrentFormData);
    }

    function handleCriteriaChange(event, values) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData.criterios_list = values;
        setCurrentFormData(localCurrentFormData);
    }

    function handleStartDateChange(date) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData["start_date"] = date;
        setCurrentFormData(localCurrentFormData);
    }

    function handleEndDateChange(date) {
        const localCurrentFormData = {...currentFormData};
        localCurrentFormData["end_date"] = date;
        setCurrentFormData(localCurrentFormData);
    }

    const filterOptions = createFilterOptions({
        stringify: (option) => option.label + " " + option.description,
        ignoreAccents: true,
        ignoreCase: true,
    });

    return <GenericModal>
        <form onSubmit={submitChanges}>
            <Grid container maxWidth="sm" spacing={1.5}>
                <Grid item xs={12} sm={12}>
                    <TextFormInput
                        defaultValue={column.headerName}
                        onChange={handleFormFieldChange}
                        id="name"
                        label="Título"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}
                                              adapterLocale={es}
                                              localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                                      adapterLocale={es}
                                                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                                >
                                    <DatePicker
                                        id="start-date"
                                        inputFormat='dd MMMM yyyy'
                                        label="Inicio Temporalización"
                                        openTo="day"
                                        views={['day']}
                                        value={currentFormData.start_date}
                                        onChange={handleStartDateChange}
                                        required
                                        fullWidth
                                        sx={{m: '1%', ml: '2%'}}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                required: true,
                                                size: "small",
                                                fullWidth: true,
                                                sx: {m: '1%', minWidth: '48%'}
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    id="end-date"
                                    inputFormat='dd MMMM yyyy'
                                    label="Final Temporalización"
                                    openTo="day"
                                    views={['day']}
                                    value={currentFormData.end_date}
                                    onChange={handleEndDateChange}
                                    required
                                    fullWidth
                                    sx={{m: '1%', ml: '2%'}}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                            size: "small",
                                            fullWidth: true,
                                            sx: {m: '1%', minWidth: '48%'},
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                defaultValue={column.percentage}
                                id="percentage"
                                label="Porcentaje de la nota final"
                                fullWidth
                                required
                                onChange={handleFormFieldChange}
                                type="number"
                                sx={{m: '1%', minWidth: '48%'}}
                                size='small'
                                variant='standard'
                                autoComplete="off"
                                helperText="Si seleccionas 0%, este contenido no contará para la media final ni para el resumen LOMLOE."
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        defaultValue={column.mark_type}
                        id="mark-type"
                        options={ACTIVITY_TYPES}
                        label="Tipo de Nota"
                        onChange={handleTypeFieldChange}
                        size="small"
                        renderInput={(params) => <TextField sx={{m: '1%', minWidth: '48%'}}
                                                            {...params} id="type" variant="standard"
                                                            required label="Tipo de Nota"/>}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Autocomplete
                    id="criterios"
                    name='criterios'
                    multiple
                    freeSolo
                    fullWidth
                    value={currentFormData.criterios_list}
                    onChange={handleCriteriaChange}
                    disableCloseOnSelect
                    options={props.criterios}
                    filterOptions={filterOptions}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.label}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip variant="outlined" label={option.label} {...getTagProps({index})}/>
                        ));
                    }
                }
                    renderOption={(props, option, state) => {
                        const isContained = currentFormData.criterios_list.some(element => element.id === option.id);
                        return <>
                            <li {...props}>
                                <Grid container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={2}
                                      columns={16}
                                >
                                    <Grid item xs={2}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={state.selected || isContained}
                                        />
                                    </Grid>
                                    <Grid item xs={14}>
                                        <Typography
                                            component='div'><strong>{option.label}</strong>: {option.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                            <Divider/>
                        </>
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{m: '1%', minWidth: '48%'}}
                            {...params}
                            margin="dense"
                            fullWidth
                            id="criteria"
                            label="Criterios LOMLOE"
                            size='medium'
                            name='criteria'
                            variant="standard"
                            helperText="Si no seleccionas ningún criterio, esta columna no contará para el resumen LOMLOE."
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <DialogActions>
                    <Button onClick={props.onClose}>Cancelar</Button>
                    <Button type="submit" variant='contained'>Guardar</Button>
                </DialogActions>
            </Grid>
        </form>
    </GenericModal>
}