import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from "react";
import MeetingsEventsService from "../../services/MeetingsEventsService";
import PlannerService from "../../services/PlannerService";
import TutoriasService from "../../services/TutoriasService";
import {Skeleton} from "@mui/material";
import {parseISO} from "date-fns";
import {EventNoteTwoTone as EventNote, FamilyRestroomTwoTone as FamilyRestroom, ScheduleTwoTone as Schedule} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";

export default function EventsSummary(props) {
    const theme = useTheme();
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [events, setEvents] = useState([]);
    let localDate = new Date(props.date);
    const eventsTypeDictionary = {
        "MEETING": "Reunión",
        "TUTORIA": "Tutoría",
        "PLANNER": "Clase"
    }

    useEffect(() => {
        if (isFirstLoad) {
            localDate.setHours(0, 0, 0, 0);
            const start = localDate.toISOString();
            localDate.setHours(23, 59, 59, 999);
            const end = localDate.toISOString();
            Promise.all([
                MeetingsEventsService.getExistingMeetingEvents(start, end),
                PlannerService.getExistingPlannerEvent(start, end),
                TutoriasService.getExistingTutoriasEvents(start, end),
            ]).then(([dataMeetings, dataPlanner, dataTutorias]) => {
                    setEvents(dataMeetings.data.concat(dataPlanner.data).concat(dataTutorias.data));
                    setIsFirstLoad(false);
                },
                _ => props.handleAPIError("Ha ocurrido un error cargando tus datos. Por favor, inténtalo de nuevo más tarde.")
            );
        }
    })

    function getTimelineItems() {
        const localEvents = events.sort((a, b) => a.start.localeCompare(b.start));
        return (<>{localEvents.map((event, i) => {
            const start = new Date(parseISO(event.start));
            return (<TimelineItem key={i}>
                <TimelineOppositeContent
                    sx={{m: 'auto 0'}}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </TimelineOppositeContent>
                <TimelineSeparator >
                    <TimelineConnector/>
                    <TimelineDot sx={{color: theme.palette.primary.light}} variant="outlined">
                        {getEventIcon(event.type)}
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent sx={{py: '12px', px: 2}}>
                    <Typography variant="h6" component="span">
                        {eventsTypeDictionary[event.type]}
                    </Typography>
                    <Typography>{event.title}</Typography>
                </TimelineContent>
            </TimelineItem>);
        })}</>)
    }

    function getEventIcon(eventType) {
        switch(eventType) {
            case "TUTORIA":
                return <FamilyRestroom/>
            case "PLANNER":
                return <Schedule/>
            case "MEETING":
                return <EventNote/>
            default:
                return <Schedule/>
        }
    }

    if (isFirstLoad) {
        return <Skeleton variant="rectangular" height={"1000px"}/>
    }

    if (events.length < 1) {
        return <Box sx={{mt: 1}}><Typography align="center">¡No tienes eventos para este día! 🥳</Typography></Box>
    }

    return (
        <Timeline position="alternate">
            {getTimelineItems()}
        </Timeline>
    );
}